import * as tslib_1 from "tslib";
import { Events, NavController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../user/user.service';
import * as firebase from 'firebase';
import { map, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfigService } from '../config/config.service';
import { CartService } from '../cart/cart.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { LabelService } from '../label/label.service';
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic/storage";
import * as i3 from "@angular/fire/firestore";
import * as i4 from "../user/user.service";
import * as i5 from "@angular/router";
import * as i6 from "../config/config.service";
import * as i7 from "../loggly-logger/loggly-logger.service";
import * as i8 from "../label/label.service";
import * as i9 from "@angular/fire/storage";
import * as i10 from "../cart/cart.service";
var OrderService = /** @class */ (function () {
    function OrderService(events, storage, afs, userService, navController, router, modalController, loadingController, alertController, configService, logglyService, labelService, angularFireStorage, cartService, fbStorage) {
        this.events = events;
        this.storage = storage;
        this.afs = afs;
        this.userService = userService;
        this.navController = navController;
        this.router = router;
        this.modalController = modalController;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.configService = configService;
        this.logglyService = logglyService;
        this.labelService = labelService;
        this.angularFireStorage = angularFireStorage;
        this.cartService = cartService;
        this.fbStorage = fbStorage;
    }
    OrderService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('order:payWithRazorPay', function (order, razorpayId, method) {
            _this.payWithRazorPay(order, razorpayId, method);
        });
        this.events.subscribe('order:payWithPaytm', function (order) {
            _this.payWithPaytm(order);
        });
        this.events.subscribe('order:sendPaymentRequest', function (orderId, userId) {
            _this.sendPaymentRequest(orderId, userId);
        });
        this.events.subscribe('order:payWithCash', function (order) {
            _this.payWithCash(order);
        });
        this.events.subscribe('order:updatePaymentComplete', function (orderId) {
            _this.updatePaymentComplete(orderId);
        });
        this.events.subscribe('order:completePaymentWithWallet', function (order) {
            _this.completePaymentWithWallet(order);
        });
        //auto confirm...
        this.events.subscribe('order:ac_payWithRazorPay', function (order, razorpayId, method) {
            _this.ac_payWithRazorPay(order, razorpayId, method);
        });
        this.events.subscribe('order:ac_payWithPaytm', function (order) {
            _this.ac_payWithPaytm(order);
        });
        this.events.subscribe('order:ac_payWithCash', function (order) {
            _this.ac_payWithCash(order);
        });
        this.events.subscribe('order:ac_completePaymentWithWallet', function (order) {
            _this.ac_completePaymentWithWallet(order);
        });
        this.events.subscribe('order:ac_paytmWebInitiateTxn', function (order) {
            _this.ac_paytmWebInitiateTxn(order);
        });
        this.events.subscribe('order:ac_completeUPIManualPayment', function (order, paymentImg) {
            _this.ac_completeUPIManualPayment(order, paymentImg);
        });
        this.events.subscribe('order:ac_completeCustomOptionPayment', function (order, response) {
            _this.ac_completeCustomOptionPayment(order, response);
        });
        this.events.subscribe('order:placeOrder', function (products, listOfCommentImages, address, paymentObj) {
            _this.placeOrder(products, listOfCommentImages, address, paymentObj);
        });
        this.events.subscribe('order:autoConfirmPlaceOrder', function (products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon) {
            _this.autoConfirmPlaceOrder(products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon);
        });
    };
    OrderService.prototype.payWithRazorPay = function (order, razorpayId, method) {
        var _this = this;
        //// console.log('service order pay razorpayId:', razorpayId)
        try {
            var payableAmnt_1 = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
            this.storage.get('storeInfo').then(function (data) {
                var options = {
                    description: _this.configService.environment.razorpay.description,
                    currency: _this.configService.environment.razorpay.currency,
                    key: razorpayId,
                    amount: Math.round(payableAmnt_1 * 100),
                    name: data.storeName,
                    image: _this.configService.environment.razorpay.image,
                    prefill: {
                        method: method,
                        contact: _this.userService.getPhoneNo(),
                        name: _this.userService.getUserName(),
                        email: _this.userService.getUserEmail() ? _this.userService.getUserEmail() : ''
                    },
                    theme: _this.configService.environment.razorpay.theme
                };
                var successCallback = (function (payment_id) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var paymentDetails, saveOrderPaymentDetails;
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: 
                            //// console.log(payment_id);
                            return [4 /*yield*/, this.presentLoading()];
                            case 1:
                                //// console.log(payment_id);
                                _a.sent();
                                paymentDetails = {
                                    order: order,
                                    mode: 'razorpay',
                                    txnRes: { paymentId: payment_id },
                                    amount: options.amount
                                };
                                saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                                saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                    var paymentChatMsg;
                                    return tslib_1.__generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                                this.loading.dismiss();
                                                paymentChatMsg = {
                                                    author: 'user',
                                                    createdAt: new Date(),
                                                    isRead: true,
                                                    orderId: order.orderId,
                                                    published: true,
                                                    status: 'PaymentMsg',
                                                    type: 'order',
                                                    paymentMode: 'razorpay'
                                                };
                                                return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                            case 1:
                                                _a.sent();
                                                this.presentAlert('Payment is successful!');
                                                _a.label = 2;
                                            case 2: return [2 /*return*/];
                                        }
                                    });
                                }); });
                                return [2 /*return*/];
                        }
                    });
                }); });
                var cancelCallback = function (error) {
                    if (error.code !== 0) {
                        _this.presentFailureAlert();
                    }
                };
                RazorpayCheckout.open(options, successCallback, cancelCallback);
            });
        }
        catch (error) {
            console.dir(error);
            this.events.publish('order:paymentFailure');
        }
    };
    OrderService.prototype.payWithPaytm = function (order) {
        var _this = this;
        var mobileNo = this.userService.getPhoneNo().slice(3);
        //// console.log(typeof mobileNo, mobileNo);
        var payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
        var getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
        getCheckSum({
            orderId: order.orderId.toString(),
            customerId: order.userId,
            phoneNo: mobileNo,
            txnAmount: payableAmnt.toString(),
        }).then(function (result) {
            //// console.log('checksum:', result.data.checksum);
            var paytmParams = {
                MID: result.data.mid,
                ORDER_ID: order.orderId.toString(),
                CUST_ID: order.userId,
                CHANNEL_ID: _this.configService.environment.paytm.CHANNEL_ID,
                TXN_AMOUNT: payableAmnt.toString(),
                WEBSITE: _this.configService.environment.paytm.WEBSITE,
                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + order.orderId,
                INDUSTRY_TYPE_ID: _this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                MOBILE_NO: mobileNo,
                CHECKSUMHASH: result.data.checksum,
                ENVIRONMENT: _this.configService.environment.paytm.ENVIRONMENT
            };
            var successCallback = function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var paymentDetails, saveOrderPaymentDetails;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(response.STATUS == "TXN_SUCCESS")) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.presentLoading()];
                        case 1:
                            _a.sent();
                            paymentDetails = {
                                order: order,
                                mode: 'paytm',
                                txnRes: response,
                                amount: paytmParams.TXN_AMOUNT
                            };
                            saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                            saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var paymentChatMsg;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                            this.loading.dismiss();
                                            paymentChatMsg = {
                                                author: 'user',
                                                createdAt: new Date(),
                                                isRead: true,
                                                orderId: order.orderId,
                                                published: true,
                                                status: 'PaymentMsg',
                                                type: 'order',
                                                paymentMode: 'paytm'
                                            };
                                            return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                        case 1:
                                            _a.sent();
                                            this.presentAlert('Payment is successful!');
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                            return [3 /*break*/, 3];
                        case 2:
                            this.presentFailureAlert();
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            var failureCallback = function (error) {
                _this.presentFailureAlert();
            };
            paytm.startPayment(paytmParams, successCallback, failureCallback);
        }).catch(function (error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            //// console.log("Error", code, message, details);
            this.presentFailureAlert();
        });
    };
    OrderService.prototype.sendPaymentRequest = function (orderId, userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentReqMsg;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentReqMsg = {
                        author: 'admin',
                        createdAt: new Date(),
                        isRead: true,
                        orderId: orderId,
                        published: true,
                        status: 'PaymentRequest',
                        type: 'order'
                    };
                    this.events.publish('chat:sendMsg', paymentReqMsg, userId);
                    this.events.publish('order:sendPaymentRequestSuccess');
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.payWithCash = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentWithCash;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentWithCash = firebase.functions().httpsCallable('payments-paymentWithCash');
                    paymentWithCash(order).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.paymentChatMsgs('cash', order)];
                                case 1:
                                    _a.sent();
                                    this.events.publish('order:modeSetToCashSuccess');
                                    return [3 /*break*/, 3];
                                case 2:
                                    this.presentFailureAlert();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.updatePaymentComplete = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRef, orderData, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        orderData = _a.sent();
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderData[0].id).update({
                                payment: {
                                    completed: true,
                                    mode: 'cash'
                                }
                            })];
                    case 2:
                        _a.sent();
                        this.events.publish('order:updatePaymentCompleteSuccess');
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.completePaymentWithWallet = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentWithWallet;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentWithWallet = firebase.functions().httpsCallable('payments-completePaymentWithWallet');
                    paymentWithWallet(order).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var paymentChatMsg;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                    this.events.publish('order:completePaymentWithWalletSuccess');
                                    paymentChatMsg = {
                                        author: 'user',
                                        createdAt: new Date(),
                                        isRead: true,
                                        orderId: order.orderId,
                                        published: true,
                                        status: 'PaymentMsg',
                                        type: 'order',
                                        paymentMode: 'wallet'
                                    };
                                    return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 3];
                                case 2:
                                    this.presentFailureAlert();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.paymentChatMsgs = function (chatObj, order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var chatRef, chatSnap, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        chatRef = this.afs.collection('chats').doc(order.userId).collection('messages', function (ref) { return ref
                            .where('orderId', '==', order.orderId)
                            .where('status', 'in', ['Confirmed', 'PaymentRequest']); });
                        return [4 /*yield*/, chatRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        chatSnap = _a.sent();
                        index = 0;
                        _a.label = 2;
                    case 2:
                        if (!(index < chatSnap.length)) return [3 /*break*/, 5];
                        //// console.log('msg id of do payment', chatSnap[index].id);
                        return [4 /*yield*/, this.afs.collection('chats').doc(order.userId).collection('messages').doc(chatSnap[index].id).delete()];
                    case 3:
                        //// console.log('msg id of do payment', chatSnap[index].id);
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        index++;
                        return [3 /*break*/, 2];
                    case 5:
                        this.events.publish('chat:sendMsg', chatObj, order.userId);
                        return [2 /*return*/];
                }
            });
        });
    };
    //Auto confrim order functions
    OrderService.prototype.ac_payWithRazorPay = function (order, razorpayId, method) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, listOfCommentImages, uploads, payableAmnt_2, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        order['createdAt'] = new Date();
                        orderId = this.afs.collection('orders').ref.doc().id;
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 4:
                        _a.sent();
                        order['orderDocId'] = orderId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order.userId);
                        payableAmnt_2 = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                        this.storage.get('storeInfo').then(function (data) {
                            var options = {
                                description: _this.configService.environment.razorpay.description,
                                currency: _this.configService.environment.razorpay.currency,
                                key: razorpayId,
                                amount: Math.round(payableAmnt_2 * 100),
                                name: data.storeName ? data.storeName : '',
                                image: _this.configService.environment.razorpay.image,
                                prefill: {
                                    method: method,
                                    contact: _this.userService.getPhoneNo(),
                                    name: _this.userService.getUserName(),
                                },
                                theme: _this.configService.environment.razorpay.theme
                            };
                            var successCallback = (function (payment_id) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var paymentDetails, ac_saveOrderPaymentDetails;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    paymentDetails = {
                                        order: order,
                                        mode: 'razorpay',
                                        txnRes: { paymentId: payment_id },
                                        amount: options.amount
                                    };
                                    ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                                    ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        return tslib_1.__generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                                    this.loading.dismiss();
                                                    this.presentAlert('Order has been placed successfully!');
                                                    return [3 /*break*/, 3];
                                                case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                                case 2:
                                                    _a.sent();
                                                    this.loading.dismiss();
                                                    this.presentFailureAlert();
                                                    _a.label = 3;
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                    return [2 /*return*/];
                                });
                            }); });
                            var cancelCallback = function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            this.loading.dismiss();
                                            return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                        case 1:
                                            _a.sent();
                                            if (error.code !== 0) {
                                                this.presentFailureAlert();
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); };
                            RazorpayCheckout.open(options, successCallback, cancelCallback);
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.paymentFailedUpdate = function (orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                                payment: {
                                    completed: false,
                                    mode: null,
                                    details: null,
                                    status: 'failed'
                                },
                                status: 'Pending'
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.dir(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithPaytm = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mobileNo, orderId, listOfCommentImages, uploads, orderRefId, payableAmnt, getCheckSum;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        mobileNo = this.userService.getPhoneNo().slice(3);
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order['createdAt'] = new Date();
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 4:
                        _a.sent();
                        order['orderDocId'] = orderId;
                        orderRefId = order.orderDocId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                        getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
                        getCheckSum({
                            orderId: orderRefId.toString(),
                            customerId: order.userId,
                            phoneNo: mobileNo,
                            txnAmount: payableAmnt.toString(),
                        }).then(function (result) {
                            var paytmParams = {
                                MID: result.data.mid,
                                ORDER_ID: orderRefId.toString(),
                                CUST_ID: order.userId,
                                CHANNEL_ID: _this.configService.environment.paytm.CHANNEL_ID,
                                TXN_AMOUNT: payableAmnt.toString(),
                                WEBSITE: _this.configService.environment.paytm.WEBSITE,
                                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + orderRefId.toString(),
                                INDUSTRY_TYPE_ID: _this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                                MOBILE_NO: mobileNo,
                                CHECKSUMHASH: result.data.checksum,
                                ENVIRONMENT: _this.configService.environment.paytm.ENVIRONMENT
                            };
                            var successCallback = function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var paymentDetails, ac_saveOrderPaymentDetails;
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(response.STATUS == "TXN_SUCCESS")) return [3 /*break*/, 1];
                                            paymentDetails = {
                                                order: order,
                                                mode: 'paytm',
                                                txnRes: response,
                                                amount: paytmParams.TXN_AMOUNT
                                            };
                                            ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                                            ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                                return tslib_1.__generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                                            this.loading.dismiss();
                                                            this.presentAlert('Order has been placed successfully!');
                                                            this.clearProductsInCartIfAny(order.userId);
                                                            return [3 /*break*/, 3];
                                                        case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                                        case 2:
                                                            _a.sent();
                                                            this.loading.dismiss();
                                                            this.presentFailureAlert();
                                                            _a.label = 3;
                                                        case 3: return [2 /*return*/];
                                                    }
                                                });
                                            }); });
                                            return [3 /*break*/, 3];
                                        case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                        case 2:
                                            _a.sent();
                                            this.loading.dismiss();
                                            this.presentFailureAlert();
                                            _a.label = 3;
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); };
                            var failureCallback = function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                        case 1:
                                            _a.sent();
                                            this.loading.dismiss();
                                            this.presentFailureAlert();
                                            return [2 /*return*/];
                                    }
                                });
                            }); };
                            paytm.startPayment(paytmParams, successCallback, failureCallback);
                        }).catch(function (error) {
                            var code = error.code;
                            var message = error.message;
                            var details = error.details;
                            //// console.log("Error", code, message, details);
                            this.presentFailureAlert();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_completePaymentWithWallet = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, listOfCommentImages, uploads, orderPaymentWithWallet, error_4;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order['createdAt'] = new Date();
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 1:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 3:
                        _a.sent();
                        order['orderDocId'] = orderId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        orderPaymentWithWallet = firebase.functions().httpsCallable('wallet-orderPaymentWithWallet');
                        orderPaymentWithWallet(order).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                        this.events.publish('order:ac_completePaymentWithWalletSuccess');
                                        this.clearProductsInCartIfAny(order.userId);
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                    case 2:
                                        _a.sent();
                                        this.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 5];
                    case 4:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        this.presentFailureAlert();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.clearProductsInCartIfAny = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.storage.get('buyNowOrder').then(function (val) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var cartRef, cartData, i;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!!val) return [3 /*break*/, 5];
                                cartRef = this.afs.collection('users').doc(userId).collection('cart');
                                return [4 /*yield*/, cartRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                        var data = a.payload.doc.data();
                                        var id = a.payload.doc.id;
                                        return tslib_1.__assign({ id: id }, data);
                                    }); })).pipe(first()).toPromise()];
                            case 1:
                                cartData = _a.sent();
                                i = 0;
                                _a.label = 2;
                            case 2:
                                if (!(i < cartData.length)) return [3 /*break*/, 5];
                                return [4 /*yield*/, this.cartService.deleteCartProduct(cartData[i].id)];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4:
                                i++;
                                return [3 /*break*/, 2];
                            case 5: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.ac_payWithCash = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listOfCommentImages_1, ac_paymentWithCash;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    order['payment'] = {
                        completed: false,
                        mode: 'cash',
                        details: {
                            amount: order.totalAmountToPaid - order.walletAmount - order.cashbackAmount
                        }
                    };
                    listOfCommentImages_1 = order['listOfCommentImages'];
                    ac_paymentWithCash = firebase.functions().httpsCallable('payments-ac_paymentWithCash');
                    ac_paymentWithCash(order).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var orderId, uploads;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 4];
                                    orderId = res.data.orderDocId;
                                    if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                                    return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                                case 1:
                                    uploads = _a.sent();
                                    return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                                            "uploadedDoc.uploads": uploads
                                        })];
                                case 2:
                                    _a.sent();
                                    _a.label = 3;
                                case 3:
                                    this.addCommentImgs(listOfCommentImages_1, orderId);
                                    this.events.publish('order:ac_modeSetToCashSuccess');
                                    this.clearProductsInCartIfAny(order.userId);
                                    return [3 /*break*/, 5];
                                case 4:
                                    this.presentFailureAlert();
                                    _a.label = 5;
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    // paytm web...
    OrderService.prototype.ac_paytmWebInitiateTxn = function (order) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderRefId_1, initiateParams, initiateTxn;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    orderRefId_1 = this.afs.collection('orders').ref.doc().id;
                    initiateParams = {
                        orderId: orderRefId_1.toString(),
                        customerId: order.userId,
                        txnAmount: (order.totalAmountToPaid - order.walletAmount - order.cashbackAmount).toString()
                    };
                    initiateTxn = firebase.functions().httpsCallable('payments-initiateTxnApi');
                    initiateTxn(initiateParams).then(function (res) {
                        //// console.log('token...', res.data);
                        _this.events.publish('order:initiateTxnSuccess', res.data, orderRefId_1.toString());
                    });
                }
                catch (error) {
                    console.dir(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.presentLoading = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('loading');
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: 'Please Wait...',
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_completeUPIManualPayment = function (order, paymentImg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, uploads, orderRef, orderData, imgRef, downloadURL, listOfCommentImages, walletObj, updateUserWalletAmount, error_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 13, , 14]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        order['createdAt'] = new Date();
                        order['status'] = 'Confirmed';
                        order['scheduledDate'] = order.scheduledDate ? new Date(order.scheduledDate) : '';
                        order['scheduledTime'] = order.scheduledTime;
                        orderId = '';
                        if (!!order.orderId) return [3 /*break*/, 4];
                        orderId = this.afs.collection('orders').ref.doc().id;
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [3 /*break*/, 6];
                    case 4:
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', order.orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 5:
                        orderData = _a.sent();
                        orderId = orderData[0].id;
                        _a.label = 6;
                    case 6:
                        imgRef = this.angularFireStorage.ref("upiManualPayment/" + orderId + "/image/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(paymentImg, 'data_url')];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 8:
                        downloadURL = _a.sent();
                        order['payment'] = {
                            completed: true,
                            mode: 'upiManual',
                            screenshot: downloadURL,
                            status: 'completed'
                        };
                        if (!!order.orderId) return [3 /*break*/, 10];
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 9:
                        _a.sent();
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order.userId);
                        return [3 /*break*/, 12];
                    case 10: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update(order)];
                    case 11:
                        _a.sent();
                        _a.label = 12;
                    case 12:
                        this.loading.dismiss();
                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                        if (order.walletAmount > 0 || order.cashbackAmount > 0) {
                            walletObj = {
                                walletAmount: order.walletAmount,
                                cashbackAmount: order.cashbackAmount,
                                orderId: orderId,
                                userId: order.userId
                            };
                            updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
                            updateUserWalletAmount(walletObj).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    console.log(res);
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        return [3 /*break*/, 14];
                    case 13:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        error_5['location'] = 'order-service:ac_completeUPIManualPayment';
                        this.logglyService.log(error_5);
                        return [3 /*break*/, 14];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.presentAlert = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            backdropDismiss: false,
                            buttons: [{
                                    text: 'ok',
                                    handler: function () {
                                        _this.modalController.dismiss();
                                        _this.navController.navigateRoot(['order-successful']);
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.presentFailureAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.loading) {
                            this.loading.dismiss();
                        }
                        return [4 /*yield*/, this.alertController.create({
                                message: 'Payment is failed! Any amount debited will be refunded in 4 - 5 working days.',
                                backdropDismiss: false,
                                buttons: [{
                                        text: 'Ok',
                                        handler: function () {
                                            _this.navController.navigateRoot(['user-order-history']);
                                        }
                                    }]
                            })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.addCommentImgs = function (listOfCommentImages, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, pid, imgs, _b, imgs_1, img, imgRef;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(Object.keys(listOfCommentImages).length !== 0)) return [3 /*break*/, 6];
                        _i = 0, _a = Object.keys(listOfCommentImages);
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 6];
                        pid = _a[_i];
                        imgs = listOfCommentImages[pid];
                        _b = 0, imgs_1 = imgs;
                        _c.label = 2;
                    case 2:
                        if (!(_b < imgs_1.length)) return [3 /*break*/, 5];
                        img = imgs_1[_b];
                        imgRef = this.fbStorage.ref("ordersCommentImgs/" + orderId + "/images/" + pid + "/" + new Date().getTime() + '.png');
                        return [4 /*yield*/, imgRef.putString(img, 'data_url')];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        _b++;
                        return [3 /*break*/, 2];
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.placeOrder = function (products, listOfCommentImages, address, paymentObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderObj, orderId, uploads, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        paymentObj['products'] = products;
                        paymentObj['address'] = address;
                        return [4 /*yield*/, this.getOrderObject(paymentObj)];
                    case 1:
                        orderObj = _a.sent();
                        orderId = this.afs.collection('orders').ref.doc().id;
                        if (!(orderObj.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, orderObj.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        orderObj.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(orderObj)];
                    case 4:
                        _a.sent();
                        if (!address.lat) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({
                                deliveryLatLng: {
                                    lat: address.lat,
                                    lng: address.lng
                                }
                            })];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(orderObj.userId);
                        this.events.publish('order:orderSuccessfullyPlaced');
                        return [3 /*break*/, 8];
                    case 7:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.autoConfirmPlaceOrder = function (products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderObj, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        paymentObj['products'] = products;
                        paymentObj['address'] = address;
                        return [4 /*yield*/, this.getOrderObject(paymentObj)];
                    case 1:
                        orderObj = _a.sent();
                        orderObj['status'] = 'Pending';
                        orderObj['payment']['status'] = 'pending';
                        if (address.lat) {
                            orderObj['deliveryLatLng'] = {
                                lat: address.lat,
                                lng: address.lng
                            };
                        }
                        orderObj['listOfCommentImages'] = listOfCommentImages;
                        orderObj['isCodAvailableForCoupon'] = isCodAvailableForCoupon;
                        this.events.publish('order:autoConfirmPlaceOrderSuccess', orderObj);
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _a.sent();
                        console.dir(error_7);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.getOrderObject = function (paymentObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var region, userId;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('region')];
                    case 1:
                        region = _a.sent();
                        return [4 /*yield*/, this.storage.get('uid')];
                    case 2:
                        userId = _a.sent();
                        return [2 /*return*/, tslib_1.__assign({}, paymentObj, { orderId: null, status: 'Pending', createdAt: new Date(), payment: {
                                    completed: false,
                                    mode: null,
                                    details: null
                                }, userId: userId, msgId: this.afs.collection('chats').doc(userId).collection('messages').ref.doc().id, userName: this.userService.getUserName(), discount: 0, region: region && region.name ? region.name : '', vendorId: region && region.vendorId ? region.vendorId : '' })];
                }
            });
        });
    };
    OrderService.prototype.getUploadedDocUrls = function (orderId, uploads) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var _i, uploads_1, img, imgRef, _a;
                        return tslib_1.__generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _i = 0, uploads_1 = uploads;
                                    _b.label = 1;
                                case 1:
                                    if (!(_i < uploads_1.length)) return [3 /*break*/, 5];
                                    img = uploads_1[_i];
                                    imgRef = this.angularFireStorage.ref("orders/" + orderId + "/uploadedDoc/" + new Date().getTime() + '.png');
                                    return [4 /*yield*/, imgRef.putString(img.url, 'data_url')];
                                case 2:
                                    _b.sent();
                                    _a = img;
                                    return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                                case 3:
                                    _a.url = _b.sent();
                                    _b.label = 4;
                                case 4:
                                    _i++;
                                    return [3 /*break*/, 1];
                                case 5:
                                    resolve(uploads);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    OrderService.prototype.saveResaleData = function (resale, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var error_8;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update({ resale: resale })];
                                case 1:
                                    _a.sent();
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_8 = _a.sent();
                                    console.dir(error_8);
                                    error_8['location'] = 'user-service:saveResaleData';
                                    this.logglyService.log(error_8);
                                    resolve(false);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    OrderService.prototype.ac_completeCustomOptionPayment = function (order, userResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, uploads, orderRef, orderData, downloadURL, imgRef, listOfCommentImages, walletObj, updateUserWalletAmount, error_9;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 14, , 15]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        order['createdAt'] = new Date();
                        order['status'] = 'Confirmed';
                        order['scheduledDate'] = order.scheduledDate ? new Date(order.scheduledDate) : '';
                        order['scheduledTime'] = order.scheduledTime;
                        orderId = '';
                        if (!!order.orderId) return [3 /*break*/, 4];
                        orderId = this.afs.collection('orders').ref.doc().id;
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [3 /*break*/, 6];
                    case 4:
                        orderRef = this.afs.collection('orders', function (ref) { return ref.where('orderId', '==', order.orderId); });
                        return [4 /*yield*/, orderRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 5:
                        orderData = _a.sent();
                        orderId = orderData[0].id;
                        _a.label = 6;
                    case 6:
                        downloadURL = '';
                        if (!userResponse.image) return [3 /*break*/, 9];
                        imgRef = this.fbStorage.ref("customPayment/" + orderId + "/image/" + new Date().getTime().toString() + '.png');
                        return [4 /*yield*/, imgRef.putString(userResponse.image, 'data_url')];
                    case 7:
                        _a.sent();
                        return [4 /*yield*/, imgRef.getDownloadURL().pipe(first()).toPromise()];
                    case 8:
                        downloadURL = _a.sent();
                        _a.label = 9;
                    case 9:
                        order['payment'] = {
                            completed: true,
                            mode: 'custom',
                            optionName: userResponse.optionName,
                            screenshot: downloadURL,
                            textDetails: userResponse.textDetails,
                            status: 'completed'
                        };
                        if (!!order.orderId) return [3 /*break*/, 11];
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 10:
                        _a.sent();
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order.userId);
                        return [3 /*break*/, 13];
                    case 11: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).update(order)];
                    case 12:
                        _a.sent();
                        _a.label = 13;
                    case 13:
                        this.loading.dismiss();
                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                        if (order.walletAmount > 0 || order.cashbackAmount > 0) {
                            walletObj = {
                                walletAmount: order.walletAmount,
                                cashbackAmount: order.cashbackAmount,
                                orderId: orderId,
                                userId: order.userId
                            };
                            updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
                            updateUserWalletAmount(walletObj).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    console.log(res);
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        return [3 /*break*/, 15];
                    case 14:
                        error_9 = _a.sent();
                        console.dir(error_9);
                        error_9['location'] = 'order-service:ac_completeCustomOptionPayment';
                        this.logglyService.log(error_9);
                        return [3 /*break*/, 15];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithStripe = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var order_1, orderId, listOfCommentImages, uploads, payableAmnt_3, stripeData, payWithStripe, error_10;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        order_1 = data.order;
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order_1['createdAt'] = new Date();
                        listOfCommentImages = tslib_1.__assign({}, order_1['listOfCommentImages']);
                        delete order_1['listOfCommentImages'];
                        if (!(order_1.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order_1.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order_1.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order_1)];
                    case 4:
                        _a.sent();
                        order_1['orderDocId'] = orderId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order_1.userId);
                        payableAmnt_3 = order_1.partialPayment.status ? order_1.partialPayment.online.amount : (order_1.totalAmountToPaid - (order_1.walletAmount + order_1.cashbackAmount));
                        stripeData = {
                            amount: payableAmnt_3 * 100,
                            currency: this.configService.environment.currencyCode,
                            token: data.token
                        };
                        payWithStripe = firebase.functions().httpsCallable('payments-payWithStripe');
                        payWithStripe(stripeData).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentDetails, ac_saveOrderPaymentDetails;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log('res of pay with stripe', res);
                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                        paymentDetails = {
                                            order: order_1,
                                            mode: 'stripe',
                                            txnRes: res.data.txnRes,
                                            amount: payableAmnt_3
                                        };
                                        ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                                        ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                            return tslib_1.__generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                                        this.loading.dismiss();
                                                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                                                        return [3 /*break*/, 3];
                                                    case 1: return [4 /*yield*/, this.paymentFailedUpdate(order_1.orderDocId)];
                                                    case 2:
                                                        _a.sent();
                                                        this.loading.dismiss();
                                                        this.presentFailureAlert();
                                                        _a.label = 3;
                                                    case 3: return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.paymentFailedUpdate(order_1.orderDocId)];
                                    case 2:
                                        _a.sent();
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 6];
                    case 5:
                        error_10 = _a.sent();
                        console.dir(error_10);
                        error_10['location'] = 'order-service:ac_payWithRazorPay';
                        this.logglyService.log(error_10);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.payWithStripe = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var order_2, payableAmnt_4, stripeData, payWithStripe, error_11;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        order_2 = data.order;
                        payableAmnt_4 = order_2.partialPayment.status ? order_2.partialPayment.online.amount : (order_2.totalAmountToPaid - (order_2.walletAmount + order_2.cashbackAmount));
                        stripeData = {
                            amount: payableAmnt_4 * 100,
                            currency: this.configService.environment.currencyCode,
                            token: data.token
                        };
                        payWithStripe = firebase.functions().httpsCallable('payments-payWithStripe');
                        payWithStripe(stripeData).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentDetails, saveOrderPaymentDetails;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                console.log('res of pay with stripe', res);
                                if (res.data.status && res.data.status === 'success') {
                                    paymentDetails = {
                                        order: order_2,
                                        mode: 'stripe',
                                        txnRes: res.data.txnRes,
                                        amount: payableAmnt_4
                                    };
                                    saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                                    saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                        var paymentChatMsg;
                                        return tslib_1.__generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                                    paymentChatMsg = {
                                                        author: 'user',
                                                        createdAt: new Date(),
                                                        isRead: true,
                                                        orderId: order_2.orderId,
                                                        published: true,
                                                        status: 'PaymentMsg',
                                                        type: 'order',
                                                        paymentMode: 'stripe'
                                                    };
                                                    return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order_2)];
                                                case 1:
                                                    _a.sent();
                                                    this.loading.dismiss();
                                                    this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                                                    return [3 /*break*/, 3];
                                                case 2:
                                                    this.loading.dismiss();
                                                    this.presentFailureAlert();
                                                    _a.label = 3;
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                                else {
                                    this.loading.dismiss();
                                    this.presentFailureAlert();
                                }
                                return [2 /*return*/];
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_11 = _a.sent();
                        console.dir(error_11);
                        error_11['location'] = 'order-service:payWithRazorPay';
                        this.logglyService.log(error_11);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_prepareOrderForPayment = function (order, paymentType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderId, listOfCommentImages, uploads, payableAmnt, error_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        orderId = this.afs.collection('orders').ref.doc().id;
                        order['createdAt'] = new Date();
                        listOfCommentImages = tslib_1.__assign({}, order['listOfCommentImages']);
                        delete order['listOfCommentImages'];
                        if (!(order.uploadedDoc.uploads.length !== 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads)];
                    case 2:
                        uploads = _a.sent();
                        order.uploadedDoc['uploads'] = uploads;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.afs.collection('orders').doc(orderId).set(order)];
                    case 4:
                        _a.sent();
                        order['orderDocId'] = orderId;
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.clearProductsInCartIfAny(order.userId);
                        payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                        if (paymentType && (paymentType === 'paypal' || paymentType === 'cashfree')) {
                            this.loading.dismiss();
                        }
                        return [2 /*return*/, { payableAmnt: payableAmnt, order: order }];
                    case 5:
                        error_12 = _a.sent();
                        console.dir(error_12);
                        error_12['location'] = 'order-service:prepareOrderForPayment';
                        this.logglyService.log(error_12);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithPaypal = function (order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentDetails, ac_saveOrderPaymentDetails, error_13;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        paymentDetails = {
                            order: order,
                            mode: 'paypal',
                            txnRes: txnRes,
                            amount: payableAmnt
                        };
                        ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                        ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                        this.loading.dismiss();
                                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                    case 2:
                                        _a.sent();
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_13 = _a.sent();
                        console.log(error_13);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.payWithPaypal = function (order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentDetails, saveOrderPaymentDetails, error_14;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        paymentDetails = {
                            order: order,
                            mode: 'paypal',
                            txnRes: txnRes,
                            amount: payableAmnt
                        };
                        saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                        saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentChatMsg;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                        paymentChatMsg = {
                                            author: 'user',
                                            createdAt: new Date(),
                                            isRead: true,
                                            orderId: order.orderId,
                                            published: true,
                                            status: 'PaymentMsg',
                                            type: 'order',
                                            paymentMode: 'paypal'
                                        };
                                        return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                    case 1:
                                        _a.sent();
                                        this.loading.dismiss();
                                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                                        return [3 /*break*/, 3];
                                    case 2:
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_14 = _a.sent();
                        console.dir(error_14);
                        error_14['location'] = 'order-service:payWithRazorPay';
                        this.logglyService.log(error_14);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.ac_payWithCashfree = function (order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentDetails, ac_saveOrderPaymentDetails;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentDetails = {
                        order: order,
                        mode: 'cashfree',
                        txnRes: txnRes,
                        amount: payableAmnt
                    };
                    console.log('paymentDetails:', paymentDetails);
                    ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                    ac_saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 1];
                                    this.loading.dismiss();
                                    this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, this.paymentFailedUpdate(order.orderDocId)];
                                case 2:
                                    _a.sent();
                                    this.loading.dismiss();
                                    this.presentFailureAlert();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.payWithCashfree = function (order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentDetails, saveOrderPaymentDetails;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    paymentDetails = {
                        order: order,
                        mode: 'cashfree',
                        txnRes: txnRes,
                        amount: payableAmnt
                    };
                    console.log('paymentDetails from manual:', paymentDetails);
                    saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                    saveOrderPaymentDetails(paymentDetails).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var paymentChatMsg;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(res.data.status && res.data.status === 'success')) return [3 /*break*/, 2];
                                    paymentChatMsg = {
                                        author: 'user',
                                        createdAt: new Date(),
                                        isRead: true,
                                        orderId: order.orderId,
                                        published: true,
                                        status: 'PaymentMsg',
                                        type: 'order',
                                        paymentMode: 'cashfree'
                                    };
                                    return [4 /*yield*/, this.paymentChatMsgs(paymentChatMsg, order)];
                                case 1:
                                    _a.sent();
                                    this.loading.dismiss();
                                    this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                                    return [3 /*break*/, 3];
                                case 2:
                                    this.loading.dismiss();
                                    this.presentFailureAlert();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'order-service:payWithRazorPay';
                    this.logglyService.log(error);
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
                return [2 /*return*/];
            });
        });
    };
    OrderService.prototype.createOrderCashfree = function (orderData, autoConfirm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orderDataCashfree, payableAmount, _a, payableAmnt, order, payableAmnt, orderObj, obj, createOrderCashfree, error_15;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _b.sent();
                        orderDataCashfree = void 0;
                        payableAmount = void 0;
                        if (!autoConfirm) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.ac_prepareOrderForPayment(orderData, 'cashfree')];
                    case 2:
                        _a = _b.sent(), payableAmnt = _a.payableAmnt, order = _a.order;
                        payableAmount = payableAmnt;
                        console.log('order:', order);
                        orderDataCashfree = order;
                        return [3 /*break*/, 4];
                    case 3:
                        payableAmnt = orderData.partialPayment.status ? orderData.partialPayment.online.amount : (orderData.totalAmountToPaid - (orderData.walletAmount + orderData.cashbackAmount));
                        payableAmount = payableAmnt;
                        orderDataCashfree = orderData;
                        _b.label = 4;
                    case 4:
                        orderObj = {
                            orderAmnt: parseFloat(payableAmount.toFixed(2)),
                            userId: orderData.userId,
                            source: 'website'
                        };
                        obj = {
                            cashfreeOrder: orderDataCashfree,
                            autoConfirm: autoConfirm,
                            payableAmount: payableAmount
                        };
                        return [4 /*yield*/, this.afs.collection('payment').doc('cashfree').collection('users').doc(orderObj.userId).set(obj)];
                    case 5:
                        _b.sent();
                        createOrderCashfree = firebase.functions().httpsCallable('payments-createOrderCashfree');
                        createOrderCashfree(orderObj).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var payment_link;
                            return tslib_1.__generator(this, function (_a) {
                                if (res.data) {
                                    console.log('res.data==', res.data);
                                    payment_link = res.data.payment_link;
                                    window.open(payment_link, "_self");
                                }
                                else {
                                    this.loading.dismiss();
                                    this.presentFailureAlert();
                                }
                                return [2 /*return*/];
                            });
                        }); }).catch(function (error) {
                            _this.loading.dismiss();
                            _this.presentFailureAlert();
                            console.log('err:::::::::::', error);
                        });
                        return [3 /*break*/, 7];
                    case 6:
                        error_15 = _b.sent();
                        this.loading.dismiss();
                        this.presentFailureAlert();
                        console.log('err from catch:', error_15);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.prototype.getOrderDetailsCashfree = function (cashfreeOrderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj, paymentSettingsDoc, autoConfirm_1, getOrderDetailsCashfree, error_16;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        console.log('cashfreeOrderId:', cashfreeOrderId);
                        return [4 /*yield*/, this.presentLoading()];
                    case 1:
                        _a.sent();
                        obj = {
                            cashfreeOrderId: cashfreeOrderId
                        };
                        console.log('reading payemnt info');
                        return [4 /*yield*/, this.afs.collection('payment').doc('info').valueChanges().pipe(first()).toPromise()];
                    case 2:
                        paymentSettingsDoc = _a.sent();
                        autoConfirm_1 = true;
                        if (paymentSettingsDoc) {
                            autoConfirm_1 = paymentSettingsDoc.autoConfirmOrder;
                        }
                        console.log('autoConfirm:', autoConfirm_1);
                        getOrderDetailsCashfree = firebase.functions().httpsCallable('payments-getOrderDetailsCashfree');
                        getOrderDetailsCashfree(obj).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var cashfreeOrder, payableAmount, userCashfreeDoc, txnRes;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log('res:', res);
                                        // const cashfreeOrder = await this.storage.get('cashfreeOrder');
                                        // const payableAmount = await this.storage.get('payableAmountCashfree');
                                        // const autoConfirm = await this.storage.get('autoConfirm');
                                        console.log('res for details:', res);
                                        if (!res.data) return [3 /*break*/, 8];
                                        return [4 /*yield*/, this.afs.collection('payment').doc('cashfree').collection('users').doc(res.data.customer_details.customer_id).valueChanges().pipe(first()).toPromise()];
                                    case 1:
                                        userCashfreeDoc = _a.sent();
                                        cashfreeOrder = userCashfreeDoc.cashfreeOrder;
                                        payableAmount = userCashfreeDoc.payableAmount;
                                        console.log('cashfreeOrder:', cashfreeOrder);
                                        console.log('payableAmount:', payableAmount);
                                        if (!(res.data.order_status == 'PAID')) return [3 /*break*/, 2];
                                        txnRes = {
                                            order_status: res.data.order_status,
                                            cf_order_id: res.data.cf_order_id,
                                            created_at: res.data.created_at,
                                            order_id: res.data.order_id,
                                            order_amount: res.data.order_amount,
                                        };
                                        if (autoConfirm_1) {
                                            console.log('successfully paid from autoconfirm:');
                                            this.ac_payWithCashfree(cashfreeOrder, txnRes, payableAmount);
                                        }
                                        else {
                                            console.log('successfully paid from Manualconfirm:');
                                            this.payWithCashfree(cashfreeOrder, txnRes, payableAmount);
                                        }
                                        return [3 /*break*/, 7];
                                    case 2:
                                        if (!autoConfirm_1) return [3 /*break*/, 4];
                                        console.log('payment failed AutoConfrm');
                                        return [4 /*yield*/, this.paymentFailedUpdate(cashfreeOrder.orderDocId)];
                                    case 3:
                                        _a.sent();
                                        return [3 /*break*/, 6];
                                    case 4:
                                        console.log('payment failed Manual Confrm');
                                        return [4 /*yield*/, this.paymentFailedUpdate(cashfreeOrder.id)];
                                    case 5:
                                        _a.sent();
                                        _a.label = 6;
                                    case 6:
                                        console.log('inside failed with orderId:', cashfreeOrder);
                                        //await this.paymentFailedUpdate(cashfreeOrder.orderDocId);
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        console.log('not paid');
                                        _a.label = 7;
                                    case 7: return [3 /*break*/, 10];
                                    case 8:
                                        console.log('else of res.data');
                                        return [4 /*yield*/, this.paymentFailedUpdate(cashfreeOrder.orderDocId)];
                                    case 9:
                                        _a.sent();
                                        this.loading.dismiss();
                                        this.presentFailureAlert();
                                        _a.label = 10;
                                    case 10: return [2 /*return*/];
                                }
                            });
                        }); }).catch(function (error) {
                            console.log('err:::::::::::', error);
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_16 = _a.sent();
                        console.log('outside catch err error:', error_16);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    OrderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.AngularFirestore), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i6.ConfigService), i0.ɵɵinject(i7.LogglyLoggerService), i0.ɵɵinject(i8.LabelService), i0.ɵɵinject(i9.AngularFireStorage), i0.ɵɵinject(i10.CartService), i0.ɵɵinject(i9.AngularFireStorage)); }, token: OrderService, providedIn: "root" });
    return OrderService;
}());
export { OrderService };
