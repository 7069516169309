import * as tslib_1 from "tslib";
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { HomePage } from 'src/app/home/home.page';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
var UtilsService = /** @class */ (function () {
    function UtilsService(modalController, platform, loadingController, alertController) {
        this.modalController = modalController;
        this.platform = platform;
        this.loadingController = loadingController;
        this.alertController = alertController;
    }
    UtilsService.prototype.openLoginModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: HomePage,
                            backdropDismiss: false,
                            cssClass: "custom-modal login-modal",
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function () {
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UtilsService.prototype.consoleLog = function (key, log) {
        //console.log(key, log)
    };
    UtilsService.prototype.getDeviceWidth = function () {
        return this.platform.width();
    };
    UtilsService.prototype.presentAlert = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            buttons: ['OK']
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UtilsService.prototype.presentLoading = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: msg,
                                duration: 9000,
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.AlertController)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
