import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './services/config/config.service';
export function LanguageLoader(http) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
var appConfig = function (config) {
    return function () {
        return config.loadConfig();
    };
};
var ɵ0 = appConfig;
var ɵ1 = (LanguageLoader);
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { NgxMasonryModule } from 'ngx-masonry';
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
