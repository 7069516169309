import * as i0 from "@angular/core";
var ConsoleService = /** @class */ (function () {
    function ConsoleService() {
    }
    ConsoleService.prototype.consoleLog = function (label, data) {
        console.log(label, data);
    };
    ConsoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsoleService_Factory() { return new ConsoleService(); }, token: ConsoleService, providedIn: "root" });
    return ConsoleService;
}());
export { ConsoleService };
