import * as tslib_1 from "tslib";
import { SharedService } from './../shared/shared.service';
import { UserService } from './../user/user.service';
import { ConfigService } from './../config/config.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events, Platform } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as firebase from 'firebase';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
import * as i4 from "../config/config.service";
import * as i5 from "../user/user.service";
import * as i6 from "../shared/shared.service";
var ProductSubscriptionsService = /** @class */ (function () {
    function ProductSubscriptionsService(events, afs, logglyService, configService, userService, platform, sharedService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.configService = configService;
        this.userService = userService;
        this.platform = platform;
        this.sharedService = sharedService;
        this.subscriptionCollectionRef = this.afs.collection('subscriptions');
    }
    ProductSubscriptionsService.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('product-subscriptions:saveSettings', function (data) {
            _this.saveSettings(data);
        });
        this.events.subscribe('product-subscriptions:getSettings', function () {
            _this.getSettings();
        });
        this.events.subscribe('product-subscriptions:payWithCash', function (subData) {
            _this.payWithCash(subData);
        });
        this.events.subscribe('product-subscriptions:payWithRazorpay', function (subData, mode, razorpayId) {
            _this.payWithRazorpay(subData, mode, razorpayId);
        });
        this.events.subscribe('product-subscriptions:payWithPaytm', function (subData) {
            _this.payWithPaytm(subData);
        });
        this.events.subscribe('product-subscriptions:getSubscriptionsOfUser', function () {
            _this.getSubscriptionsOfUser();
        });
        this.events.subscribe('product-subscriptions:toggleActive', function (sid, status, index) {
            _this.toggleActive(sid, status, index);
        });
        this.events.subscribe('product-subscriptions:removeSub', function (sid, index) {
            _this.removeSub(sid, index);
        });
        this.events.subscribe('product-subscriptions:getSubscriptions', function () {
            _this.getSubscriptions();
        });
    };
    ProductSubscriptionsService.prototype.saveSettings = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('subscription').set(data)];
                    case 1:
                        _a.sent();
                        this.events.publish('product-subscriptions:saveSettingsSuccess');
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir(error_1);
                        error_1['location'] = 'product-subscriptions-service:saveSettings';
                        this.logglyService.log(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.getSettings = function (returnType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var settings, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('settings').doc('subscription').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        settings = _a.sent();
                        if (returnType == 'return') {
                            return [2 /*return*/, settings];
                        }
                        else {
                            this.events.publish('product-subscriptions:publishSettings', settings);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.dir(error_2);
                        error_2['location'] = 'product-subscriptions-service:getSettings';
                        this.logglyService.log(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.payWithCash = function (subData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, error_3;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, , 4]);
                        subData['createdAt'] = new Date();
                        subData['payment'] = {
                            mode: 'cash'
                        };
                        _a = subData;
                        _b = 'user';
                        _c = {};
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        _a[_b] = (_c.id = _d.sent(),
                            _c.name = this.userService.getUserName(),
                            _c);
                        return [4 /*yield*/, this.subscriptionCollectionRef.add(subData)];
                    case 2:
                        _d.sent();
                        this.events.publish('product-subscriptions:subscriptionCreatedSuccess');
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _d.sent();
                        console.dir(error_3);
                        this.events.publish('product-subscriptions:subscriptionFailed');
                        error_3['location'] = 'product-subscriptions-service:payWithCash';
                        this.logglyService.log(error_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.payWithRazorpay = function (subData, mode, razorpayId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentMode_1, userId_1, subDoc, subDocId_1, options, successCallback_1, cancelCallback_1, error_4;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        paymentMode_1 = 'razorpay';
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        userId_1 = _a.sent();
                        subData['createdAt'] = new Date();
                        subData['payment'] = {
                            completed: false,
                            mode: paymentMode_1,
                            details: null,
                            status: 'pending'
                        };
                        subData['user'] = {
                            id: userId_1,
                            name: this.userService.getUserName()
                        };
                        return [4 /*yield*/, this.subscriptionCollectionRef.add(subData)];
                    case 2:
                        subDoc = _a.sent();
                        subDocId_1 = subDoc.id;
                        options = {
                            description: this.configService.environment.razorpay.description,
                            currency: this.configService.environment.razorpay.currency,
                            key: razorpayId,
                            amount: subData.amountPayable * 100,
                            name: this.configService.environment.storeName ? this.configService.environment.storeName : '',
                            image: this.configService.environment.razorpay.image,
                            prefill: {
                                method: mode,
                                contact: this.userService.getPhoneNo(),
                                name: this.userService.getUserName(),
                                email: this.userService.getUserEmail() ? this.userService.getUserEmail() : ''
                            },
                            theme: this.configService.environment.razorpay.theme,
                            payment_capture: 1
                        };
                        successCallback_1 = (function (payment_id) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var successPayment, paymnetHistory;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        successPayment = {
                                            completed: true,
                                            mode: paymentMode_1,
                                            details: { paymentId: payment_id },
                                            status: 'completed'
                                        };
                                        return [4 /*yield*/, this.subscriptionCollectionRef.doc(subDocId_1).update({ payment: successPayment })];
                                    case 1:
                                        _a.sent();
                                        paymnetHistory = {
                                            paidAt: new Date(),
                                            subscriptionId: subDocId_1,
                                            userId: userId_1,
                                            mode: paymentMode_1,
                                            details: { paymentId: payment_id },
                                            type: 'subscription',
                                            status: 'pending',
                                            amount: subData.amountPayable
                                        };
                                        return [4 /*yield*/, this.addPaymentHistory(paymnetHistory)];
                                    case 2:
                                        _a.sent();
                                        this.events.publish('product-subscriptions:subscriptionCreatedSuccess');
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        cancelCallback_1 = function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.paymentFailedUpdate(subDocId_1)];
                                    case 1:
                                        _a.sent();
                                        if (error.code !== 0) {
                                            this.events.publish('product-subscriptions:subscriptionFailed');
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); };
                        this.platform.resume.subscribe(function (event) {
                            RazorpayCheckout.on('payment.success', successCallback_1);
                            RazorpayCheckout.on('payment.cancel', cancelCallback_1);
                            RazorpayCheckout.onResume(event);
                        });
                        RazorpayCheckout.on('payment.success', successCallback_1);
                        RazorpayCheckout.on('payment.cancel', cancelCallback_1);
                        RazorpayCheckout.open(options);
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        console.dir(error_4);
                        this.events.publish('product-subscriptions:subscriptionFailed');
                        error_4['location'] = 'product-subscriptions-service:payWithRazorpay';
                        this.logglyService.log(error_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.payWithPaytm = function (subData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var mobileNo_1, paymentMode_2, userId_2, subDoc, subDocId_2, getCheckSum, error_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        mobileNo_1 = this.userService.getPhoneNo() ? this.userService.getPhoneNo().slice(3) : '';
                        paymentMode_2 = 'paytm';
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        userId_2 = _a.sent();
                        subData['createdAt'] = new Date();
                        subData['payment'] = {
                            completed: false,
                            mode: paymentMode_2,
                            details: null,
                            status: 'pending'
                        };
                        subData['user'] = {
                            id: userId_2,
                            name: this.userService.getUserName()
                        };
                        return [4 /*yield*/, this.subscriptionCollectionRef.add(subData)];
                    case 2:
                        subDoc = _a.sent();
                        subDocId_2 = subDoc.id;
                        getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
                        getCheckSum({
                            orderId: subDocId_2.toString(),
                            customerId: userId_2,
                            phoneNo: mobileNo_1,
                            txnAmount: subData.amountPayable.toString(),
                        }).then(function (result) {
                            // console.log('checksum:', result.data.checksum);
                            var paytmParams = {
                                MID: result.data.mid,
                                ORDER_ID: subDocId_2.toString(),
                                CUST_ID: userId_2,
                                CHANNEL_ID: _this.configService.environment.paytm.CHANNEL_ID,
                                TXN_AMOUNT: subData.amountPayable.toString(),
                                WEBSITE: _this.configService.environment.paytm.WEBSITE,
                                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + subDocId_2.toString(),
                                INDUSTRY_TYPE_ID: _this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                                MOBILE_NO: mobileNo_1,
                                CHECKSUMHASH: result.data.checksum,
                                ENVIRONMENT: _this.configService.environment.paytm.ENVIRONMENT
                            };
                            var successCallback = function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var successPayment, paymnetHistory;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(response.STATUS === 'TXN_SUCCESS')) return [3 /*break*/, 3];
                                            successPayment = {
                                                completed: true,
                                                mode: paymentMode_2,
                                                details: response,
                                                status: 'completed'
                                            };
                                            return [4 /*yield*/, this.subscriptionCollectionRef.doc(subDocId_2).update({ payment: successPayment })];
                                        case 1:
                                            _a.sent();
                                            paymnetHistory = {
                                                paidAt: new Date(),
                                                subscriptionId: subDocId_2,
                                                userId: userId_2,
                                                mode: paymentMode_2,
                                                details: response,
                                                type: 'subscription',
                                                status: 'successful',
                                                amount: subData.amountPayable
                                            };
                                            return [4 /*yield*/, this.addPaymentHistory(paymnetHistory)];
                                        case 2:
                                            _a.sent();
                                            this.events.publish('product-subscriptions:subscriptionCreatedSuccess');
                                            return [3 /*break*/, 5];
                                        case 3: return [4 /*yield*/, this.paymentFailedUpdate(subDocId_2)];
                                        case 4:
                                            _a.sent();
                                            this.events.publish('product-subscriptions:subscriptionFailed');
                                            _a.label = 5;
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            }); };
                            var failureCallback = function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.paymentFailedUpdate(subDocId_2)];
                                        case 1:
                                            _a.sent();
                                            this.events.publish('product-subscriptions:subscriptionFailed');
                                            return [2 /*return*/];
                                    }
                                });
                            }); };
                            paytm.startPayment(paytmParams, successCallback, failureCallback);
                        }).catch(function (error) {
                            var code = error.code;
                            var message = error.message;
                            var details = error.details;
                            // console.log('Error', code, message, details);
                            this.events.publish('product-subscriptions:subscriptionFailed');
                            error['location'] = 'product-subscriptions-service:payWithPaytm_1';
                            this.logglyService.log(error);
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_5 = _a.sent();
                        console.dir(error_5);
                        this.events.publish('product-subscriptions:subscriptionFailed');
                        error_5['location'] = 'product-subscriptions-service:payWithPaytm_2';
                        this.logglyService.log(error_5);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.addPaymentHistory = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('payment').doc('history').collection('payments').add(data)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        console.dir(error_6);
                        error_6['location'] = 'product-subscriptions-service:addPaymentHistory';
                        this.logglyService.log(error_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.paymentFailedUpdate = function (subId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.subscriptionCollectionRef.doc(subId).update({
                                payment: {
                                    completed: false,
                                    mode: null,
                                    details: null,
                                    status: 'failed'
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _a.sent();
                        error_7['location'] = 'product-subscriptions-service:paymentFailedUpdate';
                        this.logglyService.log(error_7);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.getSubscriptionsOfUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var uid_1, subsRef, subs, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.sharedService.getStorageUid()];
                    case 1:
                        uid_1 = _a.sent();
                        if (!uid_1) return [3 /*break*/, 3];
                        subsRef = this.afs.collection('subscriptions', function (ref) { return ref
                            .where('userId', '==', uid_1)
                            .orderBy('createdAt', 'desc'); });
                        return [4 /*yield*/, subsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 2:
                        subs = _a.sent();
                        this.events.publish('product-subscriptions:publishSubscriptionsOfUser', subs);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_8 = _a.sent();
                        console.dir(error_8);
                        error_8['location'] = 'product-subscriptions-service:getSubscriptionsOfUser';
                        this.logglyService.log(error_8);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.toggleActive = function (sid, status, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('subscriptions').doc(sid).update({ active: status })];
                    case 1:
                        _a.sent();
                        this.events.publish('product-subscriptions:toggleActiveSuccess', index);
                        return [3 /*break*/, 3];
                    case 2:
                        error_9 = _a.sent();
                        console.dir(error_9);
                        error_9['location'] = 'product-subscriptions-service:toggleActive';
                        this.logglyService.log(error_9);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.removeSub = function (sid, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.afs.collection('subscriptions').doc(sid).delete()];
                    case 1:
                        _a.sent();
                        this.events.publish('product-subscriptions:removeSubSuccess', index);
                        return [3 /*break*/, 3];
                    case 2:
                        error_10 = _a.sent();
                        console.dir(error_10);
                        error_10['location'] = 'product-subscriptions-service:removeSub';
                        this.logglyService.log(error_10);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.prototype.getSubscriptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subsRef, subs, error_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        subsRef = this.afs.collection('subscriptions', function (ref) { return ref
                            .orderBy('createdAt', 'desc'); });
                        return [4 /*yield*/, subsRef.snapshotChanges().pipe(map(function (actions) { return actions.map(function (a) {
                                var data = a.payload.doc.data();
                                var id = a.payload.doc.id;
                                return tslib_1.__assign({ id: id }, data);
                            }); })).pipe(first()).toPromise()];
                    case 1:
                        subs = _a.sent();
                        this.events.publish('product-subscriptions:publishSubscriptions', subs);
                        return [3 /*break*/, 3];
                    case 2:
                        error_11 = _a.sent();
                        console.dir(error_11);
                        error_11['location'] = 'product-subscriptions-service:getSubscriptions';
                        this.logglyService.log(error_11);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProductSubscriptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductSubscriptionsService_Factory() { return new ProductSubscriptionsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.UserService), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i6.SharedService)); }, token: ProductSubscriptionsService, providedIn: "root" });
    return ProductSubscriptionsService;
}());
export { ProductSubscriptionsService };
