import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { ConsoleService } from '../console/console.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../console/console.service";
var DesignStudioService = /** @class */ (function () {
    function DesignStudioService(angularFirestore, consoleService) {
        this.angularFirestore = angularFirestore;
        this.consoleService = consoleService;
        this.designStudioActive = false;
    }
    DesignStudioService.prototype.initializeService = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        data = _a.sent();
                        this.designStudioActive = data.active;
                        console.log('designStudioActive', this.designStudioActive);
                        this.getGlobalData();
                        return [2 /*return*/];
                }
            });
        });
    };
    DesignStudioService.prototype.setHeaderData = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.angularFirestore.collection('studio').doc('website').collection('sections').doc('header').set(data)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DesignStudioService.prototype.setCatgData = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.angularFirestore.collection('studio').doc('website').collection('sections').doc('catgs').set(data)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DesignStudioService.prototype.getSessionStorageGlobalData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var data;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data = JSON.parse(localStorage.getItem("globalData")) || {};
                                    if (!!(Object.keys(data).length)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.getGlobalData()];
                                case 1:
                                    data = _a.sent();
                                    localStorage.setItem("globalData", JSON.stringify(data));
                                    _a.label = 2;
                                case 2:
                                    resolve(data);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    DesignStudioService.prototype.getGlobalData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, designStudioActive;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        data = _a.sent();
                        designStudioActive = data.active;
                        return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var globalData;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!designStudioActive) return [3 /*break*/, 2];
                                            console.log('designStudio is Active');
                                            return [4 /*yield*/, this.angularFirestore.collection('studio').doc('website').collection('sections').doc('global').valueChanges().pipe(first()).toPromise()];
                                        case 1:
                                            globalData = _a.sent();
                                            return [3 /*break*/, 3];
                                        case 2:
                                            console.log('designStudio is deActive');
                                            globalData = {
                                                productStyle: {
                                                    gridColumn: {
                                                        sm: 1,
                                                        md: 3,
                                                        lg: 5
                                                    },
                                                    fixedHeight: 150,
                                                    isFixedHeight: true,
                                                    style: "product-one"
                                                },
                                                itemStyle: {
                                                    isFixedHeight: true,
                                                    fixedHeight: 200,
                                                    showFilter: true,
                                                    gridColumn: {
                                                        lg: 5,
                                                        md: 3,
                                                        sm: 1
                                                    },
                                                    style: "category-one"
                                                }
                                            };
                                            _a.label = 3;
                                        case 3:
                                            resolve(globalData);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                }
            });
        });
    };
    DesignStudioService.prototype.getSessionStorageHeaderData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var data;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    data = JSON.parse(sessionStorage.getItem("headerData")) || {};
                                    if (!!(Object.keys(data).length)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.getHeaderData()];
                                case 1:
                                    data = _a.sent();
                                    sessionStorage.setItem("headerData", JSON.stringify(data));
                                    _a.label = 2;
                                case 2:
                                    resolve(data);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    DesignStudioService.prototype.getHeaderData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, designStudioActive;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.angularFirestore.collection('studio').doc('website').valueChanges().pipe(first()).toPromise()];
                    case 1:
                        data = _a.sent();
                        designStudioActive = data.active;
                        return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var headerDdata;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            headerDdata = {};
                                            if (!designStudioActive) return [3 /*break*/, 2];
                                            console.log('designStudio is Active');
                                            return [4 /*yield*/, this.angularFirestore.collection('studio').doc('website').collection('sections').doc('header').valueChanges().pipe(first()).toPromise()];
                                        case 1:
                                            headerDdata = _a.sent();
                                            headerDdata.designStudioActive = true;
                                            return [3 /*break*/, 3];
                                        case 2:
                                            console.log('designStudio is deActive');
                                            headerDdata.designStudioActive = false;
                                            _a.label = 3;
                                        case 3:
                                            resolve(headerDdata);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                }
            });
        });
    };
    DesignStudioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DesignStudioService_Factory() { return new DesignStudioService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.ConsoleService)); }, token: DesignStudioService, providedIn: "root" });
    return DesignStudioService;
}());
export { DesignStudioService };
